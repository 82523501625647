<template>
  <v-container grid-list-md class="grey lighten-4 fill-height">
    <v-layout row wrap>
      <v-flex xs4> </v-flex>
      <v-flex xs8 style="height: calc(100vh - 132px)"> </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { ZoomMtg } from "@zoomus/websdk";
import { zoomApiKeys, jsonToQueryParam } from "../../../library/helpers";

// ZoomMtg.setZoomJSLib("https://source.zoom.us/1.8.0/lib", "/av");
ZoomMtg.setZoomJSLib("https://source.zoom.us/1.9.5/lib", "/av");

ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();

export default {
  data() {
    return {
      conference: {},
    };
  },
  computed: {},

  mounted() {
    this.get();
    window.onbeforeunload = function(e) {
      return "Please leave the meeting first";
    };
  },
  methods: {
    get() {
      this.$rest
        .get(`/api/conference/${this.$route.params.meetingId}`)
        .then(({ data }) => {
          this.conference = data;
          this.startMeeting(
            this.conference,
            this.conference.zoom.meeting_id,
            this.conference.zoom.password
          );
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$events.fire("notification", {
              message: err.response.data.message,
              status: "error",
            });
            this.$router.push({
              name: "live-class",
            });
          }
        });
    },

    triggerConferenceHostedByTeacher(data) {
      if (
        this.$auth.loggedInRole().slug == "teacher" ||
        this.$auth.loggedInRole().slug == "administrator"
      ) {
        this.$rest
          .post("/api/conference-host", {
            conference_id: this.conference.id,
            started_at: this.conference.start_time,
            ended_at: this.conference.end_time,
            user_name: this.conference.name,
            status: "hosted",
          })
          .catch((e) => {
            this.$events.fire("notification", {
              message: e.response.data.message,
              status: "error",
            });
          });
      }
    },

    triggerAttenanceSubmitByStudent(conference_data, data) {
      const user = this.$auth.user();
      var joinUserData = {
        user_id: user.id,
        user_name: user.full_name,
        subject_id: conference_data.subject.id,
        school_id: conference_data.school_id,
        conference_id: conference_data.id,
        start_time: conference_data.start_time,
        end_time: conference_data.end_time,
        subject_name: conference_data.subject.name,
        teacher_name: conference_data.name,
        grade: conference_data.grade.name,
        status: "present",
        activities: "joined",
        enroll_id: user.student.enroll_id,
        section_id: user.student.section_id,
      };
      this.$rest
        .post("/api/zoom-attend", {
          data: joinUserData,
        })
        .then((res) => {})
        .catch((e) => {});
    },

    startMeeting(conference_data, meetingId, password) {
      if (!meetingId) return false;
      let user = this.$auth.user();
      let flag = 1;
      if (this.$auth.loggedInRole().slug === "student") {
        var data = {
          user_id: user.id,
          user_name: user.full_name,
          ...conference_data,
          subject_id: conference_data.subject.id,
          conference_id: conference_data.id,
          status: "leave",
          activities: "leaved",
          enroll_id: user.student.enroll_id,
          section_id: user.student.section_id,
          teacher_name: conference_data.name,
          grade: conference_data.grade.name,
          subject_name:conference_data.subject.name
        };
      } else {
        flag = 0;
        var data = {
          conference_id: this.conference.id,
          start_time: this.conference.start_time,
          end_time: this.conference.end_time,
          user_name: this.conference.name,
          status: "leaved",
        };
      }
      const payload = {
        apiKey: zoomApiKeys(this.$auth.company("id")),
        meetingNumber: parseInt(meetingId),
        leaveUrl:
          location.origin +
          "/live-class?" +
          jsonToQueryParam(data) +
          "&flag=" +
          flag,
        userName: user.full_name,
        userEmail: "",
        passWord: password, // if required
        role: this.$auth.loggedInRole().slug === "student" ? 0 : 1, // 1 for host; 0 for attendee
      };
      this.$rest.post(`/api/meeting/${meetingId}`, payload).then((res) => {
        let $this = this;
        ZoomMtg.init({
          leaveUrl: payload.leaveUrl,
          isSupportAV: true,
          inviteUrlFormat: "",
          disableCallOut: true, //optional
          disableInvite: true,
          debug: true,
          success: function() {
           
            ZoomMtg.inMeetingServiceListener("onMeetingStatus", function(data) {
              if (parseInt(data.meetingStatus) === 2) {
                switch ($this.$auth.loggedInRole().slug) {
                  case "student":
                    $this.triggerAttenanceSubmitByStudent(
                      conference_data,
                      data
                    );
                    break;
                  case "teacher":
                    $this.triggerConferenceHostedByTeacher(data);
                    break;
                  case "administrator":
                    $this.triggerConferenceHostedByTeacher(data);
                    break;
                  default:
                    break;
                }
              }
              // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
            });
            ZoomMtg.join({
              signature: res.data,
              meetingNumber: payload.meetingNumber,
              userName: payload.userName,
              apiKey: payload.apiKey,
              userEmail: payload.userEmail,
              passWord: payload.passWord,
              success: (success) => {
                ZoomMtg.showInviteFunction({ show: false });
              },
              error: (error) => {
              },
            });
          },
        });
      });
    },
  },
};
</script>
